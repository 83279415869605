.subscribe-section {
    background-color: #455a64;
    color: white;
    padding: 5% 10%;
    margin: 0 auto;
    text-align: center;
}

.subscribe-title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

.subscribe-description {
    margin-bottom: 20px;
}

.subscribe-input {
    padding: 10px;
    width: 70%;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.inner-container {
    width: 100%;
    margin: auto;
}

.subscribe-button {
    padding: 10px 15px;
    margin-left: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.attachment-input {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
}



@media (max-width: 600px) {
    .subscribe-input {
        width: 100%;
        margin-bottom: 10px;
    }
 .attachment-input {
        width: 100%;
        margin-bottom: 10px;
    }
    .subscribe-button {
        width: 100%;
    }
}