.about-parent-container {
  display: flex;
  width: 90%;
  margin: 4% auto;
  justify-content: space-between;
}

.left-container {
  padding-right: 2%;
  width: 65%;
}

.right-container {
  padding-top: 8%;
  width: 35%;
}

@media (max-width: 800px) {
  .left-container {
    width: 90%;
  }

  .right-container {
    width: 90%;
  }
}
